@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
}

article {
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: left;
}

article p, article ul, article ol {
  text-align: left;
  padding-bottom: 1rem;
  font-weight: 400;
}

/* Connections Game Animations */
@keyframes shake {
  0% { transform: translateX(0); }
  20% { transform: translateY(-8px); }
  40% { transform: translateX(0); }
  60% { transform: translateX(0); }
  70% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  90% { transform: translateX(-5px); }
  100% { transform: translateX(0); }
}
.shake {
  animation: shake 1s ease-in-out;
}

@keyframes rise {
  0% {transform: translateX(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateX(0); }
}
.rise {
  animation: rise 0.5s ease-in-out;
}
